.main-contact .mb-2 {
  width: 100%;
}

.main-contact .form-control::placeholder {
  color: #dc3545;
  font-style: italics;
}

.mb-2 {
  margin-top: 50px;
}

.main-contact {
  margin-top: 50px;
}

@media screen and (min-width: 1024px) {
  .main-contact {
    margin-top: 100px;
  }

  .page-contact {
    display: inline-block;
    width: 70%;
  }

  .main-contact .mb-2 {
    width: 70%;
    margin: 0 0 0 15%;
  }
}

@media screen and (min-width: 1600px) {
  .page-contact {
    display: inline-block;
    width: 80%;
  }
}
