/* CSS GLOBALE POUR LE HOME PAGE */

.sectionBackGround,.aside,.sectionPhotos{
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {
  .aside,.sectionPhotos,.sectionInfo{
    margin: 0 4% 20px 4%;
  }
}

/* TROIS SECTIONS POUR LA PAGE D'ACCUEIL */

/*section pour l'image de fond et de son titre*/

.sectionBackGround {
  background-image: url('/staticImages/tabouret.jpg');
  background-position: center center;
  background-size: cover;
  text-align: center;
  box-shadow: 1px 25px 18px 1px rgba(0, 0, 0, 0.69);
  background-color: black;
  height: 400px;
}
.sectionBackGround p {
  color: white;
  margin: 0;
  font-family: 'parisienne';
  font-size: 2em;
  padding: 50px 55% 0 0;
}

/*section pour l'aside information , titre, le text d'accorche et la carte */

aside {
  background-color: #7a2323;
  color: white;
  padding-top: 100px;
  padding-bottom: 50px;
}

aside .facebook-icon {
  font-size: 50px;
}

aside .facebook-icon:hover {
  color: white;
}

aside a {
  color: white;
}
.actu img {
  border-radius: 50%;
  width: 25%;
  border: none;
  margin-bottom: 10px;
}


aside .fab:first-child {
  font-size: 3em;
  cursor: pointer;
}

.conception div {
  display: inline-block;
  width: 20%;
}

.conception > div > p > .far,
.conception > div > p > .fas,
.conception > div > p > .fab {
  margin: 27px 0 0 0;
  font-size: 3.2em;
  border-radius: 50%;
  border: 3px solid white;
  padding: 10%;
}

.sectionInfo article{
  margin-bottom: 20px;
}

.textIndex {
  -webkit-box-shadow: 0px 0px 23px 3px #000000;
  box-shadow: 0px 0px 23px 3px #000000;
  font-style: italic;
  background-color: #e0e0e0;
  padding: 55px 8% 55px 8%;
  text-align: justify;
  font-size: 1rem;
}

.textIndex p > .fab {
  margin: 0 2% 0 2%;
}


/*section pour les photos d'accorche ( titre, image et un bouton pour aller sur la galerie */


.circle-font {
  color: rgb(170, 170, 170);
  margin-right: 5px;
}

.top-photo-title {
  display: inline-block;
  width: 65%;
  padding-left: 7%;
}

.button-more {
  display: inline-block;
  width: 90px;
}

.sectionPhotos article{
  margin-top: 40px;
}

@media screen and (min-width: 1024px) {
 .sectionInfo,.aside{
  display: inline-block;
 }

 .aside{
  width: 20%;
  vertical-align: top;
 }
 
 .sectionInfo{
  width: 70%;
 }

 .sectionInfo .title {
  margin: 16px 1% 60px 1%
}

 .sectionInfo .france,.sectionInfo .textIndex {
  display: inline-block;
  width: 45%;
 }

 .textIndex{
  margin-right: 2%;
  vertical-align: top;
 }

 .france{
  margin-left: 2%;
  vertical-align: top;
 }

 .sectionPhotos article{
  display: inline-block;
  width: 25%;
  margin:0 1% 0 1%;
 }

}

