.new-photo-modal .modal-body {
  padding-bottom: 50px;
}

.form-control {
  position: relative;
}

.invalid-feedback {
  text-align: left;
}

textarea {
  white-space: pre-wrap;
}

label {
  width: 100%;
  text-align: left;
  font-style: italic;
  margin-left: 2%;
}

.modal-body {
  margin: 0 15% 0 15%;
}

.modal-title {
  font-style: italic;
  font-size: 1.4rem;
}

.select-photo label {
  margin-top: 20px;
  padding-left: 20%;
}
