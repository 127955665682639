.aside-top-index p {
  color: white;
  font-style: italic;
}

.section-all-photo article h3 {
  font-size: 2rem;
}

.section-all-photo input[type='checkbox'] {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.photo-name {
  display: inline-block;
  width: 75%;
}

.option-crud {
  display: inline-block;
  width: 12%;
}

.aside-top-index {
  padding: 0;
}

.aside-top-index article {
  display: inline-block;
  width: 30%;
  margin-bottom: 10px;
  margin-left: 1%;
}

.selected-nbe-photo {
  width: 6rem;
  margin: auto;
  margin-bottom: 20px;
}

.section-all-photo article {
  width: 100%;
  display: inline-block;
  margin: 0 1% 40px 1%;
}

.section-all-photo img {
  border: 3px solid silver;
}

@media screen and (min-width: 501px) {
  .section-all-photo article {
    width: 17rem;
  }

  .section-all-photo article h3 {
    font-size: 1.5rem;
  }

  .section-all-photo input[type='checkbox'] {
    width: 18px;
    height: 18px;
  }

  .photo-name {
    width: 70%;
  }

  .option-crud {
    width: 16%;
  }
}

@media screen and (min-width: 1024px) {
  .selected-nbe-photo {
    display: inline-block;
  }

  .aside-top-index {
    display: inline-block;
    width: 25%;
    vertical-align: top;
    position: fixed;
    top: 20%;
    left: 3%;
  }

  .section-all-photo {
    display: inline-block;
    width: 80%;
    margin-left: 25%;
  }
}
