.div-spinner {
  padding-top: 14%;
  padding-bottom: 7%;
}

.div-spinner p {
  font-style: italic;
}

.spinner {
  font-weight: 500px;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}
