.main-partners .partner-card {
  margin-top: 50px;
}

.partner-card {
  display: inline-block;
  line-height: normal;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.partner-card img {
  width: 22%;
  border: none;
  position: absolute;
  left: 2%;
  top: 10px;
}

.partner-card .card-title {
  margin-left: 15%;
}

.partner-card .card-subtitle {
  margin: 0;
  text-align: right;
}

.partner-card .display-button-partner {
  width: 40%;
}

.partner-card .no-Partner span {
  font-style: italic;
  font-weight: 600;
}

.partner-card .description-user {
  height: 115px;
  margin-top: 50px;
}

.display-button-partner {
  width: 50%;
}

.no-partner {
  padding-top: 100px;
  font-size: larger;
}

.no-partner span {
  font-style: italic;
  font-weight: 600;
}

.no-work {
  margin: 30px 0 30px 0;
}

.pagination {
  margin-top: 20px;
}

.modal-body-video {
  margin-bottom: 40px;
}

.description-user {
  white-space: pre-wrap;
  text-align: left;
  padding-left: 3%;
}

video {
  width: 100%;
}

@media screen and (min-width: 500px) {
  .partner-card {
    width: 400px;
  }
}
