@font-face {
  font-family: 'merienda';
  src: url('../../fonts/Merienda-Regular.ttf');
}
.success {
  background-color: #28a745;
}
.alert {
  background-color: #dc3545;
}
.warning {
  background-color: #ffc107;
}
.info {
  background-color: #17a2b8;
}

.info,
.warning,
.alert,
.success {
  text-align: center;
  font-family: 'merienda';
  width: 70%;
  color: white;
  font-size: 0.7rem;
  position: 'fixed';
  top: 15%;
  right: 5%;
  z-index: 1000;
}

.reset-load {
  top: 0;
  left: 0;
}

.mr-auto {
  margin-left: 2%;
}

@media screen and (min-width: 1024) {
  .info,
  .warning,
  .alert,
  .success {
    top: 15%;
    right: 5%;
  }
}
