footer {
  color: white;
  background: black;
  padding: 50px 0 1px 0;
  margin-top: 50px;
  
}

footer h1 {
  color: white;
  font-family: 'merienda';
  margin-top: 20px;
  margin-bottom: 0;
}

footer section {
  padding-top: 20px;
  margin-bottom: 100px;
  width: 100%;
}

footer span {
  font-style: italic;
}

.alias p {
  margin: -10px;
}


@media screen and (min-width: 1024px) {
  footer section article {
    display: inline-block;
    width: 33%;
    vertical-align: middle;
  }

  .alias p {
    text-align: left;
    padding-left: 20%;
  }

  footer a {
    text-align: right;
    display: block;
    padding: 0;
    text-decoration: none;
    padding-right: 20%;
  }
}
