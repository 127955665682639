@font-face {
  font-family: 'parisienne';
  src: url('fonts/parisienne.ttf');
}
@font-face {
  font-family: 'merienda';
  src: url('fonts/Merienda-Regular.ttf');
}

img {
  max-width: 100%;
}

html {
  font-size: 62, 5%;
  color: #454a54;
}
body {
  line-height: 2em;
  text-align: center;
}

/*margin body page* without background image*/
main,
.infoHome,
.inspiration-home {
  min-height: 40em;
  margin: 0 5% 0 5%;
}

.main-home {
  margin: 0;
}

a {
  text-decoration: none;
}

h1 {
  text-align: center;
  font-family: 'roboto';
  color: #7a2323;
}

h3 {
  font-family: 'parisienne';
  margin: 0;
}

header,
footer {
  color: white;
  background: black;
}

.parent_spinner {
  margin-top: 350px;
  font-style: italic;
}

.btn {
  margin: 2% 1% 2% 1%;
}

@media screen and (min-width: 1024px) {
  .form-card .card-body {
    margin: 0 22% 0 22%;
  }
}
