.button-crud {
  padding: 5px 1% 0 0;
  text-align: right;
}

.font-awesome {
  font-size: 1.2rem;
  margin-bottom: 4px;
  cursor: pointer;
  margin-left: 0.5%;
}

.font-awesome:hover {
  cursor: pointer;
  color: silver;
}
