.gallery-article {
  display: inline-block;
  width: 100%;
  margin: 0 0.5% 20px 0.5%;
}

.photo-article {
  display: inline-block;
  width: 100%;
  margin: 20px 0.5% 20px 0.5%;
}

.photo-article .card-title {
  padding-top: 10px;
}

.gallery-article .card-text {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-main-photo {
  width: 20rem;
  margin: auto;
}

.no-image {
  border: 1px solid black;
}

@media screen and (min-width: 500px) {
  .gallery-article {
    width: 400px;
  }

  .select-main-photo {
    width: 250px;
  }

  .photo-article {
    width: 500px;
  }
}
