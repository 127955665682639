.login-card {
  margin: auto;
  margin-top: 100px;
  width: 90%;
}

.img-login {
  width: 10%;
  text-align: left;
  border: none;
}

.card-title {
  font-style: italic;
}

.error-message {
  color: #dc3545;
  font-style: italic;
  padding-right: 13%;
  margin-top: -10px;
  margin-bottom: -10px;
}

.visible {
  visibility: visible;
}

.no-visible {
  visibility: hidden;
}

.font-eye {
  float: right;
  margin-top: 10px;
  font-size: 1.5rem;
}

.font-eye:hover {
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .login-card {
    margin: auto;
    margin-top: 100px;
    width: 50%;
  }
}
