header h1 {
  color: white;
  font-family: merienda;
  font-size: 1.2rem;
  margin-top: 0;
  padding-top: 57px;
  padding-left: 10%;
}
.logo-title {
  margin: auto;
  position: relative;
  width: 350px;
}

header h1 span {
  font-style: italic;
}
/*gestion des tailles */
header img {
  position: absolute;
  top: -25%;
  left: -5%;
  border: none !important;
  height: 130px;
  width: 95px;
}

nav a {
  color: white;
  padding: 10px 5px 0 10px;
}

nav a:hover {
  cursor: pointer;
  color: silver;
}

nav {
  padding-bottom: 20px;
}

@media screen and (min-width: 1200px) {
  .display-header-left,
  header nav {
    display: inline-block;
  }

  .display-header-left {
    width: 78%;
    padding-left: 20%;
  }

  header nav {
    width: 20%;
  }

  header h1 {
    font-size: 2rem;
    padding-left: 0;
  }

  .logo-title {
    margin: auto;
    position: relative;
    width: 450px;
  }

  /*gestion des tailles */
  header img {
    height: 180px;
    width: 130px;
    top: -30%;
    left: -20%;
  }

  .marging-header {
    width: 80%;
    margin: auto;
  }
}
